<template>
  <div
    class="flex justify-center pointer-events-none px-6"
    @mouseover="
      setCursor(null, scrollTo, {
        'font-size': '14px',
        cursor: 'unset',
        'z-index': '-1',
      })
    "
  >
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "BubbleText",
  props: {
    setCursor: {
      required: true,
      type: Function,
    },
  },
};
</script>
